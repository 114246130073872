<template>
    <v-layout wrap style="margin-top: 20px;">
      <v-flex
        v-if="isVertical"
        md112
        xs12
      >
        <v-alert
          color="primary"
          text
        >
          <div class="text-h6">
            Estados
          </div>
          <!--div>Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem. Duis vel nibh at velit scelerisque suscipit. Praesent blandit laoreet nibh. Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc, eu sollicitudin urna dolor sagittis lacus. Etiam sollicitudin, ipsum eu pulvinar rutrum, tellus ipsum laoreet sapien, quis venenatis ante odio sit amet eros.</div-->
          <div
            v-for="(item, index) of info"
            :key="index"
          >
            <v-btn 
              elevation="0"
              small
              color="#CCCCCC"
              text
            >
              <v-icon
                :color="item.color"
              >
                mdi-calendar-month-outline
              </v-icon>
            </v-btn>
            <small style="margin-left: 5px;color: black;">
              {{item.title}}
            </small>
            <!--v-alert
              v-for="(item, index) of info"
              :key="index"
          outlined
              dense
              :color="item.color"
              icon="mdi-calendar-month-outline"
              elevation="0"
            style="background-color: white;"
            >
              <small>{{ item.title }}</small>
            </v-alert-->
          </div>
          <div>
            <small style="font-style: italic;">
              Solo se muestran los contenidos con estado "ACTIVO"
            </small>
          </div>
        </v-alert>
      </v-flex>
      <v-flex
        v-show="!isVertical"
        md3
        xs12
        v-for="(item, index) of info"
        :key="index"
        style="padding:5px;"
      >
        <v-alert
          colored-border
          dense
          :color="item.color"
          icon="mdi-calendar-month-outline"
          border="left"
          elevation="1"
        >
          <small>{{ item.title }}</small>
        </v-alert>
      </v-flex>
    </v-layout>
</template>
<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    info () {
      return [
        {
          color: 'red',
          title: 'Vencido',
          description: ''
        },
        {
          color: 'green',
          title: 'Activo',
          description: ''
        },
        {
          color: '#cdf9c5',
          title: 'Pendiente de activarse',
          description: ''
        },
        {
          color: 'gray',
          title: 'Finalizado',
          description: ''
        },
      ]
    }
  },
}
</script>

